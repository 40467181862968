@import url(https://fonts.googleapis.com/css2?family=Changa+One:ital@0;1&family=Lato:wght@400;700;900&display=swap);
body {
  font-family: 'Lato', sans-serif;
}

.header-title {
  font-family: 'Changa One', cursive;
}


.section-header h3 {
  font-size: 36px;
  color: #283d50;
  text-align: center;
  font-weight: 500;
  position: relative
}

.section-header p {
  text-align: center;
  margin: auto;
  font-size: 15px;
  padding-bottom: 60px;
  color: #556877;
  width: 50%
}

#clients {
  padding: 40px 0
}

#clients .clients-wrap {
  /* border-top: 1px solid #d6eaff; */
  /* border-left: 1px solid #d6eaff; */
  margin-bottom: 30px
}

#clients .client-logo {
  padding: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border-right: 1px solid #d6eaff; */
  /* border-bottom: 1px solid #d6eaff; */
  overflow: hidden;
  background: #fff;
  height: 160px
}

#clients img {
  transition: all 0.4s ease-in-out
}

/* #promos .container {
  width: 900px;
} */


.ftco-footer-social li {
  list-style: none;
  margin: 0 10px 0 0;
  display: inline-block;
}

.ftco-footer-social li a {
  height: 40px;
  width: 40px;
  display: block;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  position: relative;
}

.ftco-footer-social li a span {
  position: absolute;
  font-size: 20px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.ftco-footer-social li a:hover {
  color: #fff;
}

.ftco-section {
  padding: 12em 0;
}

.ftco-section h2 {
  margin-bottom: 0;
}


footer {
  padding: 7em 0;
  border-top: 5px #f5eb33 solid;
}

.footer-07 {
  background: #123d33;
}

.footer-07 a {
  /* color: #a3de83; */
  text-decoration: none;
}

.footer-07 p {
  color: rgba(255, 255, 255, 0.3);
}

.footer-07 .footer-heading {
  font-size: 30px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 30px;
}

.footer-07 .footer-heading .logo {
  color: #fff;
}

.footer-07 .menu {
  margin-bottom: 30px;
}

.footer-07 .menu a {
  color: rgba(255, 255, 255, 0.6);
  margin: 0 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.footer-07 .ftco-footer-social li a {
  background: transparent;
  /* border: 1px solid #a3de83; */
}



